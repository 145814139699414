
import React, { useContext } from 'react'
import Message from './Message'

import { AppContext } from '../appContext'



function Messages() {
    const context = useContext(AppContext)

    return (
        <>
            {context.state.messages && context.state.messages.map((item) => {
                return <Message message={item} key={item.typeID} />
            })
            }

        </>
    )

}

export default Messages