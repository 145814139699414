import React, { useEffect, useRef, useState } from 'react';
import './App.css';
import CustomInput from './components/CustomInput';
import Messages from './components/Messages';
import CustomHeader from './components/CustomHeader';
import { AppContext, initialContext } from './appContext'
import useSWR from 'swr'
import CircularProgress from '@material-ui/core/CircularProgress'
import Grid from '@material-ui/core/Grid'
import Alert from '@material-ui/lab/Alert'
import { getParameterByName } from './helpers'

const fetcher = (url, body) => fetch(url, { method: 'POST', body: JSON.stringify(body) }).then(r => r.json())

function App() {
  // TODO: validate input params
  const event = JSON.parse(getParameterByName('event'))
  let url = `${event.lambda_url}getMessages`

  const [state, setState] = useState(initialContext)

  useEffect(() => {
    if (!state.mounted) {
      setState((currentState) => {
        return {
          ...currentState, 
          event: event, 
          objectID: event.app_oid.toString() + "--" + event.id.toString(),
          userID: event.user_oid,
          appID: event.app_oid,
          userDisplay: event.display_name,
          mounted: true
          }
      })
      console.log('[vsc-conversation-mounted]')
      window.parent.postMessage({type: 'vsc-conversation-opened', data: event}, '*')
    }
  }, [state])

  const scrollRef = useRef(null)
  const { data, error, mutate } = useSWR( state.objectID ? url : null, () => fetcher(url, { objectID: state.objectID }))

  useEffect(() => {
    const timer = setInterval(() => {
      mutate()
    }, 10000);
    return () => clearInterval(timer);
  }, [mutate]);


  useEffect(() => {
    if (data && data.messages) {
      const updateState = async () => {
        await setState(currentState => {
          return { ...currentState, messages: data.messages }
        })
        setTimeout(() => scrollRef.current.scrollTop = scrollRef.current.scrollHeight, 10)
      }
      updateState()
    }
  }, [data])


  if (error) {
    return <Alert severity="error">Error reading data.</Alert>
  }

  if (!state.messages) {
    return <Grid container justify="center">
      <CircularProgress />
    </Grid>
  }


  return (
    <AppContext.Provider value={{ state: state, setState: setState, updateMessages: mutate }}>
      <div className="App">
        <div className="headerArea">
          <CustomHeader />
        </div>
        <div className="messagesArea" ref={scrollRef} ><Messages /></div>
        <div className="inputArea">
          <CustomInput />
        </div>
      </div>
    </AppContext.Provider>
  );
}

export default App;
