import React, { useContext } from "react";
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";
import CloseIcon from "@material-ui/icons/Close";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Chip from "@material-ui/core/Chip";
import { AppContext } from "../appContext";

import { styled } from '@material-ui/core/styles'

import styles from './CustomHeader.module.css'



function CustomHeader() {
  const context = useContext(AppContext);

  const handleCloseClick = () => {
    console.log("[vsc-conversation-closing]", context.state.event);
    window.parent.postMessage({type: 'vsc-conversation-closing', data: {...context.state.event, event_id: 'vsc-conversation-closing'}}, '*');
  };

  const handleEventClick = (event) => {
    console.log(`[${event.event}]`, {...context.state.event, event_id: event.event_id});
    window.parent.postMessage({type: event.event, data: {...context.state.event, event_id: event.event_id}}, '*')
    handleCloseClick()
  };

  let buttons = []

  if (context.state.event?.buttons && (!context.state.event.read_only)) {

    const SuccessButton = styled(Button)({
      borderColor: context.state.event.colors?.success ? context.state.event.colors?.success : 'white !important',
      color: context.state.event.colors?.success ? context.state.event.colors?.success : 'white !important',
      marginRight: '1em !important',
      textTransform: 'none',
      fontSize: '11px'
    })

    const UnsuccessButton = styled(Button)({
      borderColor: context.state.event.colors?.unsuccess ? context.state.event.colors?.unsuccess + " !important" : 'white !important',
      color: context.state.event.colors?.unsuccess ? context.state.event.colors?.unsuccess + " !important" : 'white !important',
      marginRight: '1em !important',
      textTransform: 'none',
      fontSize: '11px'
    })
    
    context.state.event.buttons.forEach((buttonEvent, eventIndex) => {
      let button = null
      if ( buttonEvent.type === 'success') {
        button = <SuccessButton variant="outlined" size="small" onClick={() => handleEventClick(buttonEvent)} data-event-id={buttonEvent.event_id} data-event={buttonEvent.event} key={`btn-evt-${eventIndex}`}>{ buttonEvent.label }</SuccessButton>
      } else {
        button = <UnsuccessButton variant="outlined" size="small" onClick={() => handleEventClick(buttonEvent)} data-event-id={buttonEvent.event_id} data-event={buttonEvent.event} key={`btn-evt-${eventIndex}`}>{ buttonEvent.label }</UnsuccessButton>
      }
      buttons.push(button)
    })  
  }

  const CustomAppBar = styled(AppBar)({
    backgroundColor: context.state.event.colors?.main ? context.state.event.colors?.main + " !important" : 'darksalmon !important',
    height: "40px"
  })

  const CustomToolbar = styled(Toolbar)({
      minHeight: "40px"
  })


  const CloseButton = styled(IconButton)({
    color: context.state.event.colors?.close ? context.state.event.colors?.close + " !important" : 'white !important',
  })

  const StyledChip = styled(Chip)({})
  

  return (
    <>
    <CustomAppBar position="static">
      <CustomToolbar variant="dense">
          { buttons }
        <div style={{flexGrow: 1}}></div>
        <CloseButton size="small" color="default" aria-label="Cerrar" onClick={handleCloseClick}><CloseIcon /></CloseButton>
      </CustomToolbar>
    </CustomAppBar>
    {
      context.state.event.read_only && context.state.event.read_only_msg &&
      <div className={styles.extraBar}>
          <StyledChip label={context.state.event.read_only_msg} />
      </div>
    }
    </>
  );
}
// https://javascript.info/cross-window-communication
export default CustomHeader;
