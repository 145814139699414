import {createContext} from 'react'

const initialContext = {
    objectID: null, // Object ID we are adding comments
    userID: null, // Current userID
    userDisplay: null, // Display name for current user
    appID: null, // Display name for current user
    useSession: null, // Session ID we should sent to server to validate permissions
    extraData: null, // Data that will be returned 
    messages: null, // Messages for the current Object ID,
    mounted: false,
    event: null // Original event passed to app
  }

const AppContext = createContext(null)

export { AppContext, initialContext }