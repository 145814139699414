import React, { useState, useContext } from 'react';
import IconButton from '@material-ui/core/IconButton'
import FilledInput from '@material-ui/core/FilledInput'
import InputAdornment from '@material-ui/core/InputAdornment'
import Icon from '@material-ui/core/Icon'
import { styled } from '@material-ui/core/styles'
import { getParameterByName } from '../helpers'



import { AppContext } from '../appContext'

import styles from './CustomInput.module.css';

const CustomFilledInput = styled(FilledInput)({
  borderTopRightRadius: "0px",
  borderTopLeftRadius: "0px",
  backgroundColor: "#f3f6f7",
  color: "#4a4a4a",
  fontSize: "13px",
  padding: "18px"
})


function CustomInput() {

  const event = JSON.parse(getParameterByName('event'))
  let url = `${event.lambda_url}putMessage`

  const createMessage = (message) => {
    return fetch(url, { method: 'POST', body: JSON.stringify(message) })
  }
    

  const context = useContext(AppContext)

  const [messageText, setMessageText] = useState("")
  const [updating, setUpdating] = useState(false)

  const CustomIcon = styled(Icon)({
    color: context.state.event.colors?.main ? context.state.event.colors?.main + " !important" : 'darksalmon !important',
  })
  
  const handleClick = async (e) => {
    if (messageText.trim().length === 0) return
    setUpdating(true)

    const newMessage = {
      "objectID": context.state.objectID,
      "userID": context.state.userID,
      "userName": context.state.userDisplay,
      "message": messageText,
    }

    if (context.state.messages.length === 0) {
      console.log('vsc-conversation-first-msg-sent')
      window.parent.postMessage({type: 'vsc-conversation-first-msg-sent', data: {...context.state.event, event_id: 'vsc-conversation-first-msg-sent'}}, '*')

    }
    console.log('lenght', context.state.messages.length)
    let response = null
    try {
      response = await createMessage(newMessage)
    } catch (error) {
      alert("Error creating new message.")
      console.log('vsc-conversation-error', error)
    }

    if (response && !response.ok) {
      alert("Error creating new message.")
      console.log('vsc-conversation-error', response)
    }
    setMessageText("")
    setUpdating(false)
    window.parent.postMessage({type: 'vsc-conversation-msg-sent', data: {...context.state.event, event_id: 'vsc-conversation-msg-sent'}}, '*')

    context.updateMessages()
  }

  const handleChange = (event) => {
    setMessageText(event.target.value)
  }
//           {/* InputProps={{ classes: {underline: classes.underline} }} */}

  return (
    <div className={styles.Container}>
        <CustomFilledInput
          fullWidth
          id="main-input"
          multiline
          value={messageText}
          onChange={handleChange}
          disabled={updating || context.state.event.read_only}
          placeholder="Añadir un comentario"
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                aria-label="Añadir un comentario"
                onClick={handleClick}
                disabled={updating || context.state.event.read_only}
              >
                <CustomIcon className="fas fa-arrow-alt-from-left" />
              </IconButton>
            </InputAdornment>
          }
        />
    </div>
  )
}

export default CustomInput