import React from 'react'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import { useContext } from 'react'
import Box from '@material-ui/core/Box'
import Avatar from '@material-ui/core/Avatar'
import Grid from '@material-ui/core/Grid'
import { styled } from '@material-ui/core/styles'

import moment from 'moment'
import 'moment/locale/pt'
import 'moment/locale/es'

import { AppContext } from '../appContext'

import styles from './Message.module.css';

const CustomAvatar = styled(Avatar)({
    width: '32px',
    height: '32px',
    backgroundColor: "#bfd7f2",
    color: "#4a4a4a",
    fontSize: "12px",
    fontWeight: "bolder"
  })

function Message(props) {
    const context = useContext(AppContext)

    const acronym = (str) => {
        const matches = str.match(/\b(\w)/g)
        return matches.join('').substr(0,3)
    }
    return (
        <Box style={{margin: '18px 22px'}}>
            <Grid container spacing={0}>
                <Grid item xs="auto">
                    <CustomAvatar alt={props.message.userName} sizes="32px">{acronym(props.message.userName)}</CustomAvatar>
                </Grid>
                <Grid item xs="auto">
                    <Card className={`${props.message.userID === context.state.userID ? styles.messageContainerOwn : styles.messageContainer}`}>
                        <CardContent className={styles.cardContent}>
                            <Box><span className={styles.userName}>{props.message.userName}</span><span className={styles.timestamp}> - {moment(props.message.createdAt, "x").fromNow()}</span></Box>
                            <Box className={styles.pre}>
                                {props.message.message}
                            </Box>
                        </CardContent>
                    </Card>

                </Grid>
            </Grid>
        </Box>
    )

}

export default Message